import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { InspirationOrigin } from '@/features/inspirations/listing-page';
import { truncate } from '@/utils/types';
import { breakpoints } from '@/components/responsive';

export interface ShortInspirationTileProps {
  id: string;
  title: string;
  image: string;
  description: string;
  uplift: string;
  url: string;
  origin: InspirationOrigin;
}

export function ShortInspirationTile({
  id,
  title,
  image,
  description,
  url,
  origin,
}: ShortInspirationTileProps) {
  const onClick = () => {
    if (origin === `publicInspirations`) {
      navigate(
        `/public/inspirations/inspiration?url=${btoa(
          url,
        )}&id=${id}&bt=${origin}`,
      );
    } else {
      navigate(`/inspiration?url=${btoa(url)}&id=${id}&bt=${origin}`);
    }
    document.querySelector(`#page-layout-wrapper`)?.scrollTo(0, 0);
  };

  return (
    <Container onClick={onClick}>
      <img src={image} alt={description} />
      <Content>
        <span className="title">{title}</span>
        <span className="description">{truncate(description, 150)}</span>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  min-height: 19rem;
  grid-template-columns: 14rem 1fr;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.09), 0 2px 8px 0 rgb(7, 6, 6, 0.04);
  border: none;
  border-radius: 1rem;
  font-family: 'Eesti', serif;
  grid-gap: 4rem;
  background: white;
  cursor: pointer;

  && > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  ${breakpoints.down(`md`)} {
    overflow: hidden;
    border-radius: 3rem;
    grid-gap: 0rem;
    max-width: 72vw;
    grid-template-columns: 1fr;
    grid-template-rows: 48vh auto;

    && > img {
      border-bottom-left-radius: 0;
      height: auto;
    }
  }
`;

const Content = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 1rem;
  text-align: start;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.7rem;
  line-height: 1.5;
  font-weight: lighter;
  color: black;

  .description {
    font-size: 1.4rem;
  }

  .title {
    font-weight: bold;
    font-size: 1.6rem;
  }

  .gatsby-image-wrapper {
    align-self: center;
    justify-self: center;
  }

  ${breakpoints.down(`md`)} {
    padding: 2rem;
  }
`;
